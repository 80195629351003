<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw', position: 'relative' }"
      :header="'Alterar Prioridade da Lead'"
      :modal="true"
      :closable="false"
    >
      <form
        name="CRMChangePriority"
        class="p-col-12"
        @submit.prevent="setPriority"
        style="min-height: 15vh"
      >
        <Loading
          :active.sync="showLoader"
          :isFullPage="false"
          :zIndex="9000"
        ></Loading>
        <p v-if="!selected">
          <b>Lead {{ proposalId }} | {{ proposalSubject }}</b>
        </p>
        <p
          v-else
          v-for="proposalSelected in selected"
          :key="proposalSelected.id"
        >
          <b>Lead {{ proposalSelected.id }} | {{ proposalSelected.subject }}</b>
        </p>
        <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="proposalPriority"
                :options="priorityList"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'priority'"
                v-model="current"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('proposalPriority') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="proposalPriority"
                >Selecionar Prioridade da Lead</label
              >
            </span>
            <small
              v-if="errors.has('proposalPriority')"
              class="p-error"
              role="alert"
            >
              Prioridade é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setPriority"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import crmService from "../../services/crm.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "CrmChangePriority",
  components: {
    Loading,
  },
  props: [
    "showToast",
    "show",
    "priorityId",
    "proposalId",
    "proposalSubject",
    "selected",
  ],
  watch: {
    priorityId() {
      this.showLoader = false;
      this.$validator.pause();
      this.$validator.reset();
      this.current = !this.selected ? this.priorityId : null;
    },
  },
  data() {
    return {
      current: !this.selected ? this.priorityId : null,
      priorityList: [],
      showLoader: false,
    };
  },
  created() {
    this.getPriorityList();
  },
  methods: {
    getPriorityList() {
      return crmService
        .getPriorityList()
        .then((response) => (this.priorityList = response));
    },
    cancel() {
      return this.$emit("cancel");
    },
    setPriority() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        if (this.selected != null) {
          return this.setPriorityMultipleProposals();
        }
        this.showLoader = true;
        return crmService
          .setPriority(this.proposalId, { priority_id: parseInt(this.current) })
          .then((response) => {
            this.showLoader = false;
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao alterar a prioridade da lead",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("changedProposalPriority", {
                status: "error",
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Prioridade da lead alterada com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("changedProposalPriority", {
              status: "ok",
              result: response,
            });
          });
      });
    },
    async setPriorityMultipleProposals() {
      let noErrors = true;
      let res = null;
      this.showLoader = true;
      for (const proposal of this.selected) {
        await crmService
          .setPriority(proposal.id, { priority_id: parseInt(this.current) })
          .then((response) => {
            if (!response) {
              return (noErrors = false);
            }
            res = response;
          });
      }

      this.showLoader = false;
      if (!noErrors) {
        if (this.showToast) {
          this.$toast.add({
            severity: "error",
            summary: "Erro ao alterar a prioridade da lead",
            detail: "",
            life: 3000,
          });
        }
        return this.$emit("changedProposalPriority", {
          status: "error",
        });
      }
      if (this.showToast) {
        this.$toast.add({
          severity: "success",
          summary: `Prioridade da lead alterada com sucesso`,
          detail: ``,
          life: 3000,
        });
      }
      return this.$emit("changedProposalPriority", {
        status: "ok",
        result: res,
      });
    },
  },
};
</script>

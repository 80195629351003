<template>
  <div>
    <form class="p-col-12" @submit.prevent="save">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12 p-md-6 p-mt-4">
          <span
            class="p-float-label"
            :style="{ top: '-0.75rem', left: '-0.5rem', fontSize: '12px' }"
          >
            <label for="leadCategory">Ficheiro do Orçamento</label>
          </span>
          <FileUpload
            ref="clientBudgetInput"
            mode="basic"
            name="clientBudgetFile[]"
            chooseLabel="Ficheiro do Orçamento"
            v-validate="'required'"
            :disabled="saved"
          />
          <small
            v-if="errors.has('clientBudgetFile[]')"
            class="p-error"
            role="alert"
          >
            O Ficheiro de Orçamento é obrigatório
          </small>
        </div>
        <div class="p-col-12 p-md-6 p-mt-4">
          <span
            class="p-float-label"
            :style="{ top: '-0.75rem', left: '-0.5rem', fontSize: '12px' }"
          >
            <label for="leadCategory">Ficheiro de Custos</label>
          </span>
          <FileUpload
            ref="costsInput"
            mode="basic"
            name="fileCosts[]"
            chooseLabel="Ficheiro de Custos"
            v-validate="'required'"
            :disabled="saved"
          />
          <small
            v-if="errors.has('clientBudgetFile[]')"
            class="p-error"
            role="alert"
          >
            O Ficheiro de Custos é obrigatório
          </small>
        </div>
        <div class="p-field p-col-12 p-md-12 p-mt-4">
          <span class="p-float-label">
            <Textarea
              v-model="coments"
              :autoResize="true"
              rows="5"
              v-bind:class="['form-control']"
              :id="'coments'"
              :name="'coments'"
              :disabled="saved"
            />
            <label for="coments">Comentários</label>
          </span>
        </div>
        <div class="p-col-12 p-d-flex p-jc-end">
          <Button
            icon="pi pi-save"
            class="p-button-success"
            v-if="!saved"
            @click="save"
          />
          <Button
            icon="pi pi-times"
            class="p-button-danger p-ml-2"
            @click="removeme"
          />
        </div>

        <divider />
      </div>
    </form>
  </div>
</template>
<script>
export default {
  name: `ChangeStatusSaleForm`,
  components: {},
  props: ["index"],
  data() {
    return {
      coments: null,
      saved: false,
    };
  },
  methods: {
    removeme() {
      this.$emit("remove", this.index);
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        if (
          !isValid &&
          this.$refs.clientBudgetInput.files.length == 0 &&
          this.$refs.costsInput.files.length == 0
        ) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        this.$emit("saved", {
          index: this.index,
          clientBudgetFile: this.$refs.clientBudgetInput.files[0],
          fileCosts: this.$refs.costsInput.files[0],
          coments: this.coments,
          saved: (this.saved = true),
        });
        return (this.saved = true);
      });
    },
  },
};
</script>
